import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { getAccessToken } from '../../../../services/auth.service'
import { EVCRequested } from './EVCRequested'
import { EVCDraft } from './EVCDraft'
import { EVCActive } from './EVCActive'

enum EvcType {
  VPLS = 'VPLS',
  VXLAN = 'VXLAN',
}

export enum EndpointType {
  DEVICE = 'DEVICE',
  INTERCONNECT = 'INTERCONNECT',
  ADDRESS = 'ADDRESS',
  CONNECTION = 'CONNECTION',
}

enum InternetServiceType {
  PPPOE = 'PPPOE',
  STATIC = 'STATIC',
}

export interface InternetService {
  id: string
  type: InternetServiceType
  username?: string
  password?: string
  address: {
    block: string
    address: string
    netmask: number
  }
  router: string | null
}

export interface EVC {
  id: string
  name: string
  type: EvcType
  evc_id: number
  speed: number
  endpoints?: Endpoint[]
  internetService: InternetService[]
  state: string
  created: Date
  updated: Date
  deleted: Date | null
  company: {
    id: string
    name: string
  }
  serviceProvider: {
    id: string
    name: string
  }
}

export interface Endpoint {
  id: number
  mode: string
  type: EndpointType
  vlan: {
    inner?: number
    outer: number
  }
  connection?: {
    id: string
    speed: number
    type: string
    active: true
  }
  address?: {
    id: number
    zipcode: string
    homeNumber: string
    homeLetter: string
    homeAddition: string
    street: string
    city: string
  }
  interconnect?: {
    id: string
    name: string
    description: string
    device: {
      id: string
      managementAddressAddress: string
      deviceName: string
      deviceModel: {
        id: string
        vendor: string
      }
    }
    port: string
  }
  device?: {
    id: string
    deviceName: string
  }
  monitoring?: {
    application: string
    inverted: boolean
    downloadItem: string
    uploadItem: string
  }
  device_port?: string
}

export function EVC() {
  const { id } = useParams()

  const [evc, setEvc] = React.useState<EVC>()

  const evcQuery = useQuery({
    queryKey: ['evcs', id],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/evcs/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
  })

  React.useEffect(() => {
    if (evcQuery.data) {
      setEvc({
        id: evcQuery.data.id,
        type: evcQuery.data.type,
        name: evcQuery.data.name,
        evc_id: evcQuery.data.evc_id,
        speed: evcQuery.data.speed,
        endpoints: evcQuery.data.endpoints,
        internetService: evcQuery.data.internetService,
        state: evcQuery.data.state,
        company: {
          id: evcQuery.data.company.id,
          name: evcQuery.data.company.name,
        },
        serviceProvider: {
          id: evcQuery.data.serviceProvider.id,
          name: evcQuery.data.serviceProvider.name,
        },
        created: new Date(evcQuery.data.created),
        updated: new Date(evcQuery.data.updated),
        deleted: evcQuery.data.deleted ? new Date(evcQuery.data.deleted) : null,
      })
    }
  }, [evcQuery.data])

  if (evcQuery.isLoading) {
    return <div>Loading...</div>
  }

  if (evcQuery.isError) {
    return <div>Error</div>
  }

  if (!evc) {
    return <div>Not found</div>
  }

  switch (evc.state) {
    case 'REQUESTED':
      return <EVCRequested evc={evc} />
    case 'DRAFT':
      return <EVCDraft evc={evc} />
    case 'ACTIVE':
      return <EVCActive evc={evc} />
    default:
      return <div>ERROR</div>
  }
}
