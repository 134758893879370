import React from 'react'
import { Line } from 'react-chartjs-2'
import type { ChartOptions } from 'chart.js'
import { Chart, Filler, Legend, LineElement, LinearScale, PointElement, TimeScale, Tooltip } from 'chart.js'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FormGroup, InputGroup, Modal, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faPlus } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { faSquareTerminal } from '@fortawesome/pro-solid-svg-icons'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { renderPreview } from '../../../../util/dymo'
import { generatePassword, validatePassword, validateSsid } from '../../../../util/password'
import { prettySpeed, selectTheme } from '../../../../util/helpers'
import { getAccessToken } from '../../../../services/auth.service'
import { DarkModeContext } from '../../../../providers/DarkModeProvider'
import type { EVC, Endpoint, InternetService } from './EVC'

interface EVCActiveProps {
  evc: EVC
}

export function EVCActive({ evc }: EVCActiveProps) {
  return (
    <>
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">EVC</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-3">
              <p className="text-muted">Naam</p>
            </div>
            <div className="col-9">
              <p>{evc.name}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p className="text-muted">EVC ID</p>
            </div>
            <div className="col-9">
              <p>{evc.evc_id}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p className="text-muted">Snelheid</p>
            </div>
            <div className="col-9">
              <p>{prettySpeed(evc.speed)}</p>
            </div>
          </div>
        </div>
      </div>

      <InternetServiceCard evc={evc} />

      {/* Card showing all interconnects */}
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Interconnects</h3>
        </div>
        <div className="card-body table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Interconnect</th>
                <th>Router</th>
                <th>Poort</th>
                <th>Mode</th>
                <th>Vlan</th>
              </tr>
            </thead>
            <tbody>
              {evc.endpoints && evc.endpoints.map(endpoint => (
                <>
                  {
                    endpoint.type === 'INTERCONNECT' && (
                      <tr>
                        <td>{endpoint.interconnect?.name}</td>
                        <td>{endpoint.interconnect?.device?.deviceName}</td>
                        <td>{endpoint.interconnect?.port}</td>
                        <td>{endpoint.mode}</td>
                        <td>{endpoint.vlan.inner ? `${endpoint.vlan.outer}.${endpoint.vlan.inner}` : endpoint.vlan.outer}</td>
                      </tr>
                    )
                  }
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {evc.endpoints && evc.endpoints.map(endpoint => (
        <EndpointCard key={endpoint.id} evc={evc} endpoint={endpoint} />
      ))}
    </>

  )
}

function EndpointCard({
  evc,
  endpoint,
}: {
  evc: EVC
  endpoint: Endpoint
}) {
  switch (endpoint.type) {
    case 'DEVICE':
      return <DeviceCard evc={evc} endpoint={endpoint} />
    case 'INTERCONNECT':
      // return <InterconnectCard evc={evc} endpoint={endpoint} />;
      return <></>
  }

  return (
    <div>
      Onbekend endpoint type
    </div>
  )
}

function DeviceCard({ evc, endpoint }: { evc: EVC, endpoint: Endpoint }) {
  const healthQuery = useQuery({
    queryKey: ['modem', 'health', endpoint.device?.id],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devices/${endpoint.device?.id}/health`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
    refetchInterval: 1000 * 30,
  })

  const isMonitored = endpoint.monitoring?.downloadItem && endpoint.monitoring?.uploadItem

  return (
    <div className="card card-outline card-primary">
      <div className="card-header">
        <h3 className="card-title">Modem</h3>
        <div className="card-tools">
          {!isMonitored && (
            <span
              className="badge badge-info"
              style={{
                fontSize: '1rem',
              }}
            >
              EVC Niet Gemonitord
            </span>
          )}

          &nbsp;

          {healthQuery.isError && (
            <span
              className="badge badge-warning"
              style={{
                fontSize: '1rem',
              }}
            >
              STATUS ERROR
            </span>
          )}
          { /* Device health status flag */}
          {healthQuery.data && healthQuery.data.status === 'UP' && (
            <span
              className="badge badge-success"
              style={{
                fontSize: '1rem',
              }}
            >
              MODEM ONLINE
            </span>
          )}
          {healthQuery.data && healthQuery.data.status === 'DOWN' && (
            <span
              className="badge badge-danger"
              style={{
                fontSize: '1rem',
              }}
            >
              MODEM OFFLINE
            </span>
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-3">
            <p className="text-muted">Modem</p>
          </div>
          <div className="col-9">
            <p>{endpoint.device?.deviceName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p className="text-muted">Modem ID</p>
          </div>
          <div className="col-9">
            <p>{endpoint.device?.id}</p>
          </div>
        </div>
        {isMonitored && (
          <BandwidthCard evc={evc} endpoint={endpoint} />
        )}
      </div>
    </div>
  )
}

Chart.register(LinearScale, PointElement, Tooltip, Legend, TimeScale, LineElement, Filler)

interface GraphData {
  clock: number
  value: number
}

function BandwidthCard({ evc, endpoint }: { evc: EVC, endpoint: Endpoint }) {
  const [uploadData, setUploadData] = React.useState<GraphData[]>([{ clock: Math.floor(Date.now() / 1000), value: 0 }])
  const [downloadData, setDownloadData] = React.useState<GraphData[]>([{ clock: Math.floor(Date.now() / 1000), value: 0 }])

  const darkMode = React.useContext(DarkModeContext).isDarkMode

  const bandwidthDataQuery = useQuery({
    queryKey: ['endpoint', 'graph', endpoint.id],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/evcs/endpoint/${endpoint.id}/bandwidth`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      if (json.upload.length === 0 || json.download.length === 0) {
        throw new Error('No data')
      }

      // Also throw error if length is 1
      if (json.upload.length === 1 || json.download.length === 1) {
        throw new Error('Not enough data')
      }

      return json
    },
    refetchInterval: 1000 * 60 * 3,
  })

  React.useEffect(() => {
    if (bandwidthDataQuery && bandwidthDataQuery.data) {
      setUploadData(bandwidthDataQuery.data.upload)
      setDownloadData(bandwidthDataQuery.data.download)
    }
  }, [bandwidthDataQuery.data])

  // Get largest value
  const largestValue = Math.max(
    ...uploadData.map(data => data.value),
    ...downloadData.map(data => data.value),
  )

  let divideBy = 1
  let speedLabel = 'bps'

  // If the largest value in bps is larger than 3kbps convert to kbps
  if (largestValue > 2000) {
    divideBy = 1000
    speedLabel = 'kbps'
  }

  // If the largest value in kbps is larger than 3mbit convert to mbit
  if (largestValue / divideBy > 3000) {
    divideBy = 1000000
    speedLabel = 'mbit'
  }

  // Prepare data for the chart
  const uploadDataPoints = uploadData.map(data => ({
    x: new Date(data.clock * 1000),
    y: data.value / divideBy,
  }))

  const downloadDataPoints = downloadData.map(data => ({
    x: new Date(data.clock * 1000),
    y: data.value / divideBy,
  }))

  const chartData = {
    datasets: [
      {
        label: 'Upload',
        data: uploadDataPoints,
        borderColor: 'rgba(233, 82, 27, 1)',
        borderWidth: 1,
        pointRadius: 0, // Remove the points
        pointHoverRadius: 0, // Remove the points on hover
        backgroundColor: 'rgba(233, 82, 27, 0.2)',
        fill: true,
      },
      {
        label: 'Download',
        data: downloadDataPoints,
        borderColor: 'rgba(28, 133, 199, 1)',
        borderWidth: 1,
        pointRadius: 0, // Remove the points
        pointHoverRadius: 0, // Remove the points on hover
        fill: 'origin',
        backgroundColor: 'rgba(28, 133, 199, 0.2)',
      },
    ],
  }

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          color: 'white', // Set the default font color to white
        },
        ticks: {
          color: 'white', // Set the default font color to white
          callback: (value) => {
            const date = new Date(value)
            const dateString = date.toLocaleDateString()
            const timeString = date.toLocaleTimeString()
            return [dateString, timeString]
          },
        },
        min: downloadData[0].clock * 1000,
        // Maximum value - 48 hours
        // min: downloadData[downloadData.length - 1].clock * 1000 - (24 * 60 * 60 * 1000),
        max: downloadData[downloadData.length - 1].clock * 1000,
      },
      y: {
        title: {
          display: true,
          text: `Bandbreedte (${speedLabel})`, // Update the Y-axis label
          color: 'white', // Set the default font color to white
        },
        ticks: {
          color: 'white', // Set the default font color to white
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true, // Show tooltips on hover
        intersect: false,
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || ''
            if (label) {
              label += ': '
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y.toFixed(2)} ${speedLabel}`
            }
            return label
          },
          title: (tooltipItems) => {
            const timestamp = tooltipItems[0].parsed.x
            const formattedDate = new Date(timestamp).toLocaleString()
            return formattedDate
          },

        },
      },
      legend: {
        labels: {
          color: 'white', // Set the font color for the legend labels
        },
      },
    },
  }

  return (
    <div className="card border-0 shadow-none">

      <div className="card-body p-0" style={{ height: '300px' }}>
        <Line data={chartData} options={chartOptions} />
      </div>
      {bandwidthDataQuery.isLoading && (
        <div className={`overlay ${darkMode ? 'dark' : ''}`}>
          <i className="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
      )}
      {bandwidthDataQuery.isError && (
        <div className={`overlay ${darkMode ? 'dark' : ''}`}>
          <div className="row">
            <div className="col-1">
              <i
                className="fas fa-2x fa-exclamation-triangle text-danger"
                style={{
                  position: 'relative',
                  top: '50%',
                  transform: 'translatey(-50%)',
                }}
              >
              </i>
            </div>
            <div
              className="col-11"
              style={{
                paddingLeft: '20px',
              }}
            >
              <h3 className="text-danger">Er is iets misgegaan</h3>
              <p
                className="text-danger"
                style={{
                  margin: '0',
                // }}>Er is iets misgegaan bij het ophalen van de modems. Probeer het later opnieuw.</p>
                }}
              >
                Het is op dit moment niet mogelijk om de grafiek te laden, of er is geen beschikbaar. Probeer het later nog een keer.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function InternetServiceCard({ evc }: { evc: EVC }) {
  const [showServiceModal, setShowServiceModal] = React.useState(false)
  const [showRouterModal, setShowRouterModal] = React.useState<InternetService | null>(null)
  const [showProgrammingModal, setShowProgrammingModal] = React.useState<InternetService | null>(null)

  const navigate = useNavigate()

  return (
    <div className="card card-outline card-primary">
      <div className="card-header">
        <h3 className="card-title">Internet Service</h3>
        <div className="card-tools">
          <button type="button" className="btn btn-primary" onClick={() => setShowServiceModal(true)}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>

      {evc.internetService.length > 0 && (
        <div className="card-body">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>IP Adres</th>
                <th>PPPoE Gebruikersnaam</th>
                <th>PPPoE Wachtwoord</th>
                <th>Router</th>
                <th>Acties</th>
              </tr>
            </thead>
            <tbody>
              {evc.internetService.map(service => (
                <tr key={service.id}>
                  <td>
                    {service.address.block}
                    { service.type == 'PPPOE' ? '' : `/${service.address.netmask}`}
                  </td>
                  <td>{service.username}</td>
                  <td>{service.password}</td>
                  <td>
                    {service.router
                      ? (
                          <>
                            <button type="button" className="btn btn-success" style={{ padding: '0px 4px' }} onClick={() => navigate(`/provisioning/routers/${service.router}`)}>
                              <span className="badge badge-success">Router</span>
                            </button>
                          </>
                        )
                      : (
                          <>
                            <button type="button" className="btn btn-secondary" style={{ padding: '0px 4px' }} onClick={() => setShowRouterModal(service)}>
                              <span className="badge badge-secondary">Geen Router</span>
                            </button>
                          </>
                        )}
                  </td>
                  <td>
                    <button type="button" className="btn" style={{ padding: '0px 5px' }} onClick={() => setShowProgrammingModal(service)}>
                      <FontAwesomeIcon icon={faSquareTerminal as IconProp} style={{ fontSize: '24px' }} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      <InternetServiceModal evc={evc} show={showServiceModal} handleClose={() => setShowServiceModal(false)} />
      <InternetServiceRouterModal evc={evc} internetService={showRouterModal} handleClose={() => setShowRouterModal(null)} />
      <InternetServiceProgrammingModal evc={evc} internetService={showProgrammingModal} handleClose={() => setShowProgrammingModal(null)} />
    </div>
  )
}

function InternetServiceProgrammingModal({ evc, internetService, handleClose }: { evc: EVC, internetService: InternetService | null, handleClose: () => void }) {
  const interconnects = evc.endpoints?.filter(endpoint => endpoint.type === 'INTERCONNECT')
  const darkMode = React.useContext(DarkModeContext).isDarkMode

  // IF no inner and outer vlan is set, return null
  const programmableInternetService = interconnects && interconnects.every(interconnect => interconnect.vlan.inner && interconnect.vlan.outer)

  if (!programmableInternetService) {
    toast.error('Deze internetservice is niet geschikt voor programmeerhulp')
    handleClose()
    return null
  }

  const escapedPassword = internetService?.password?.replaceAll('?', '\\?').replaceAll('$', '\\$')

  return (
    <Modal show={internetService !== null} onHide={handleClose} size="xl">
      <Modal.Header>
        <Modal.Title>L&apos;NEA Lazy Network Engineer&apos;s Assistant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {interconnects && interconnects.map((interconnect, index) => (
          <div className="card card-outline card-primary" key={index}>
            <div className="card-header">
              <h3 className="card-title">{interconnect.interconnect?.name}</h3>
            </div>
            <div className="card-body">
              <div style={{
                transition: 'all 0.1s ease-in-out',
                backgroundColor: darkMode ? '#2d2d2d' : '#f7f7f7',
                color: darkMode ? '#a8a8a8' : '#333',
                fontFamily: 'monospace',
                padding: '0.5rem',
                borderRadius: '4px',
                overflowX: 'auto',
              }}
              >
                {`/interface vlan add interface="vlan${interconnect.vlan.outer} " mtu=1550 name="vlan${interconnect.vlan.outer}.${interconnect.vlan.inner} EVC${evc.evc_id} ${evc.name}" vlan-id=${interconnect.vlan.inner}`}
                <br />
                {`/interface pppoe-server server add disabled=no interface="vlan${interconnect.vlan.outer}.${interconnect.vlan.inner} EVC${evc.evc_id} ${evc.name}" max-mru=1500 max-mtu=1500`}
                <br />
                {`/ppp secret add name="${internetService?.username}" password="${escapedPassword}" profile=${evc.speed}Mbit remote-address=${internetService?.address.address} service=pppoe`}
              </div>
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  )
}

function InternetServiceRouterModal({ evc, internetService, handleClose }: { evc: EVC, internetService: InternetService | null, handleClose: () => void }) {
  const [serialNumber, setSerialNumber] = React.useState('')
  const [model, setModel] = React.useState('EX220')
  const [pppoeUsername, setPppoeUsername] = React.useState<string>(internetService?.username || '')
  const [pppoePassword, setPppoePassword] = React.useState<string>(internetService?.password || '')
  const [wifiSSID, setWifiSSID] = React.useState(evc.company.name)
  const [wifiPassword, setWifiPassword] = React.useState('')

  // State variables for error tracking
  const [ssidError, setSsidError] = React.useState(false)
  const [pppoePasswordError, setPppoePasswordError] = React.useState(false)

  // State variables for image data
  const [imageData, setImageData] = React.useState('')

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  React.useEffect(() => {
    setPppoeUsername(internetService?.username || '')
    setPppoePassword(internetService?.password || '')
  }, [internetService])

  React.useEffect(() => {
    setSsidError(!validateSsid(wifiSSID))
  }, [wifiSSID])

  React.useEffect(() => {
    setPppoePasswordError(!validatePassword({
      password: pppoePassword,
      requirements: {
        length: 8,
        number: true,
        specialCharacter: false,
        lowercase: true,
        uppercase: true,
      },
    }))
  }, [pppoePassword])

  React.useEffect(() => {
    setImageData('')
    if (!pppoePassword || !wifiSSID || !wifiPassword)
      return
    if (wifiPassword.length < 8)
      return
    if (!validatePassword({
      password: pppoePassword,
      requirements: {
        length: 8,
        number: true,
        specialCharacter: false,
        lowercase: true,
        uppercase: true,
      },
    })) {
      return
    }
    if (!validateSsid(wifiSSID))
      return

    const renderAndSetImageData = async () => {
      const router = {
        id: '',
        serialNumber,
        model,
        pppoeUsername,
        pppoePassword,
        wifiSSID,
        wifiPassword,
        status: '',
        configVersion: '',
      }
      const image = await renderPreview(router)

      // Need to parse the image data as it is URL encoded
      setImageData(JSON.parse(image))
    }
    renderAndSetImageData()
  }, [pppoePassword, wifiSSID, wifiPassword])

  const createRouter = async (
    serialNumber: string,
    model: string,
    pppoeUsername: string,
    pppoePassword: string,
    wifiSsid: string,
    wifiPassword: string,
  ) => {
    // Validation Checks
    if (!validateSsid(wifiSsid)) {
      return toast.error('SSID mag het & teken niet bevatten.')
    }
    if (!validatePassword({
      password: pppoePassword,
      requirements: {
        length: 8,
        number: true,
        specialCharacter: false,
        lowercase: true,
        uppercase: true,
      },
    })) {
      return toast.error('PPPoE Wachtwoord moet bestaan uit 1 hoofdletter, 1 kleine letter, 1 nummer met een minimale lengte van 8.')
    }

    const queryString = `/api/provisioning/router`

    const response = await fetch(queryString, {
      method: 'post',
      headers: new Headers({
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        serialNumber,
        model,
        pppoeUsername,
        pppoePassword,
        wifiSSID: wifiSsid,
        wifiPassword,
        internetServiceId: internetService?.id,
      }),
    })

    if (!response.ok) {
      return toast.error('Fout tijdens invoeren van de router')
    }

    toast.success('Router succesvol toegevoegd')

    queryClient.invalidateQueries({
      queryKey: ['evcs', evc.id],
    })

    handleClose()
  }

  return (
    <Modal show={internetService !== null} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Router Toevoegen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-6">
            <FormGroup>
              <label>Model</label>
              <input type="text" className="form-control" value={model} onChange={event => setModel(event.target.value)} />
            </FormGroup>
          </div>
          <div className="col-6">
            <FormGroup>
              <label>Serienummer</label>
              <input type="text" className="form-control" value={serialNumber} onChange={event => setSerialNumber(event.target.value.toLowerCase())} />
            </FormGroup>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <FormGroup>
              <label>PPPoE Gebruikersnaam</label>
              <input type="text" className="form-control" disabled value={pppoeUsername} />
            </FormGroup>
          </div>
          <div className="col-6">
            <FormGroup>
              <label>PPPoE Wachtwoord</label>
              <input type="text" className="form-control" disabled value={pppoePassword} />
              {pppoePasswordError && (
                <small className="text-danger">Het wachtwoord moet minimaal 8 karakters lang zijn en minimaal 1 cijfer bevatten</small>
              )}
            </FormGroup>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <FormGroup>
              <label>Wifi SSID</label>
              <input type="text" className={`form-control ${ssidError ? 'is-invalid' : ''}`} value={wifiSSID} onChange={event => setWifiSSID(event.target.value)} />
              {ssidError && (
                <small className="text-danger">De SSID moet minimaal 3 karakters lang zijn</small>
              )}
            </FormGroup>
          </div>
          <div className="col-6">
            <FormGroup>
              <label>Wifi Wachtwoord</label>
              <input type="text" className="form-control" value={wifiPassword} onChange={event => setWifiPassword(event.target.value)} />
              {wifiPassword.length < 8 && (
                <small className="text-danger">Het wachtwoord moet minimaal 8 karakters lang zijn</small>
              )}
            </FormGroup>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              { imageData
                ? (
                    <img src={`data:image/png;base64,${imageData}`} alt="Preview" />
                  )
                : (
                    <img src="https://placehold.co/340x140?text=Voorbeeld" alt="Preview" />
                  )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={handleClose}>Sluiten</button>
        <button type="button" className="btn btn-primary" onClick={() => createRouter(serialNumber, model, pppoeUsername, pppoePassword, wifiSSID, wifiPassword)}>Toevoegen</button>
      </Modal.Footer>
    </Modal>
  )
}

enum InternetServiceType {
  PPPOE = 'PPPOE',
  STATIC = 'STATIC',
}

const ipAddressOptions = [
  { value: 'automatic', label: 'Automagisch' },
  { value: 'manual_create', label: 'Handmatig: Maak nieuw blok' },
  { value: 'manual_existing', label: 'Handmatig: Gebruik bestaand blok' },
]

const serviceTypeOptions = [
  { value: InternetServiceType.PPPOE, label: 'PPPoE' },
  { value: InternetServiceType.STATIC, label: 'Statisch' },
]

interface ExistingBlock {
  id: string
  network: string
  netmask: number
  version: string
  name: string | null
  note: string | null
  autoSelect: boolean
  isPublic: boolean
  isInternal: boolean
  isManagement: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

interface ExistingBlockSelectType {
  value: ExistingBlock
  label: string
}

function InternetServiceModal({ evc, show, handleClose }: { evc: EVC, show: boolean, handleClose: () => void }) {
  const endpoint = evc.endpoints?.find(endpoint => endpoint.type === 'DEVICE')
  const hasAddress = endpoint?.address !== undefined
  const defaultUsername = hasAddress ? `${endpoint?.address?.city}.${endpoint?.address?.street}${endpoint?.address?.homeNumber}${endpoint?.address?.homeLetter ? endpoint?.address?.homeLetter : ''}${endpoint?.address?.homeAddition ? `-${endpoint?.address?.homeAddition}` : ''}.${evc.company.name}` : `-${evc.company.name}`
  const [type, setType] = React.useState<InternetServiceType>(InternetServiceType.PPPOE)
  const [username, setUsername] = React.useState(defaultUsername.toLocaleLowerCase().replace(/ /g, ''))
  const [editUsername, setEditUsername] = React.useState(!hasAddress)
  const [password, setPassword] = React.useState(generatePassword({
    length: 10,
    number: true,
    specialCharacter: true,
    lowercase: true,
    uppercase: true,
  }))
  const [editPassword, setEditPassword] = React.useState(false)
  const [ipAddressType, setIpAddressType] = React.useState(ipAddressOptions[0].value)
  const [selectedBlock, setSelectedBlock] = React.useState<ExistingBlockSelectType>()
  const [hostAddress, setHostAddress] = React.useState('')
  const [subnetMask, setSubnetMask] = React.useState('')

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const queryClient = useQueryClient()

  const existingBlocksQuery = useQuery({
    queryKey: ['existingBlocks', evc.id],
    enabled: ipAddressType === 'manual_existing',
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/ip/block?isInternal=false&isPublic=true&autoSelect=false&limit=10000`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
  })

  let blockOptions = []

  if (existingBlocksQuery.data && existingBlocksQuery.data.result.length > 0) {
    blockOptions = existingBlocksQuery.data.result.map((block: ExistingBlock) => ({
      value: block,
      label: `${block.network}/${block.netmask}`,
    }))
  }

  const handleTypeChange = (option: any) => {
    setType(option.value)
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handleIpAddressTypeChange = (option: any) => {
    setIpAddressType(option.value)
  }

  const handleBlockChange = (selectedOption: any) => {
    setSelectedBlock(selectedOption)
    setHostAddress('')
    setSubnetMask('')
  }

  const handleHostAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHostAddress(event.target.value)
  }

  const handleSubnetMaskChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubnetMask(event.target.value)
  }

  const handleSubmit = async () => {
    const data = {
      evc_id: evc.id,
      type,
      ipAddress: {},
      username,
      password,
    }

    if (ipAddressType === 'automatic') {
      data.ipAddress = {
        automatic: true,
      }
    }

    if (ipAddressType === 'manual_create') {
      data.ipAddress = {
        manual: {
          create: {
            address: `${hostAddress}`,
          },
        },
        netmask: subnetMask,
      }
    }

    if (ipAddressType === 'manual_existing') {
      data.ipAddress = {
        manual: {
          existing: {
            blockID: selectedBlock?.value.id,
            address: hostAddress,
          },
        },
      }
    }

    const result = await fetch(`/api/netwerk/internet-service`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(data),
    })

    const json = await result.json()

    if (json.error) {
      toast.info('Er is iets misgegaan bij het toevoegen van de internet service')
      toast.error(json.message)
      return
    }

    toast.success('Internet Service toegevoegd')

    queryClient.invalidateQueries({
      queryKey: ['evcs', evc.id],
    })

    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Internet Service Toevoegen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Show disabled field for customer */}
        <FormGroup>
          <label>Klant</label>
          <input type="text" className="form-control" value={evc.company.name} disabled />
        </FormGroup>
        <FormGroup>
          <label>Type</label>
          <Select
            options={serviceTypeOptions}
            onChange={handleTypeChange}
            theme={selectStyle}
            value={serviceTypeOptions.find(option => option.value === type)}
          />
        </FormGroup>
        {type === InternetServiceType.PPPOE && (
          <>
            <FormGroup>
              <label>Gebruikersnaam</label>
              <InputGroup>
                <input type="text" className="form-control" value={username} onChange={handleUsernameChange} disabled={!editUsername} />
                {!editUsername && (
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => setEditUsername(true)}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </button>
                  </div>
                )}
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <label>Wachtwoord</label>
              <InputGroup>
                <input type="text" className="form-control" value={password} onChange={handlePasswordChange} disabled={!editPassword} />
                {!editPassword && (
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => setEditPassword(true)}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </button>
                  </div>
                )}
              </InputGroup>
            </FormGroup>
          </>
        )}
        <FormGroup>
          <label>IP Adres</label>
          <Select
            options={ipAddressOptions}
            onChange={handleIpAddressTypeChange}
            theme={selectStyle}
            value={ipAddressOptions.find(option => option.value === ipAddressType)}
          />
        </FormGroup>
        {ipAddressType === 'manual_create' && (
          <FormGroup>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="hostAddress">Host Adres</label>
                <input type="text" id="hostAddress" className="form-control" value={hostAddress} onChange={handleHostAddressChange} />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="subnetMask">Subnet Mask</label>
                <input type="text" id="subnetMask" className="form-control" value={subnetMask} onChange={handleSubnetMaskChange} />
              </div>
            </div>
          </FormGroup>
        )}
        {ipAddressType === 'manual_existing' && (
          <FormGroup>
            <label>Selecteer Blok</label>
            <Select
              options={blockOptions}
              value={selectedBlock}
              onChange={handleBlockChange}
              isSearchable
              theme={selectStyle}
            />
          </FormGroup>
        )}
        {ipAddressType === 'manual_existing' && selectedBlock && (
          <FormGroup>
            <label htmlFor="hostAddress">Host Adres</label>
            <input type="text" id="hostAddress" className="form-control" value={hostAddress} onChange={handleHostAddressChange} />
          </FormGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={handleClose}>Sluiten</button>
        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Toevoegen</button>
      </Modal.Footer>
    </Modal>
  )
}
